<template>
  <header>
    <div class="container-fluid">
      <div class="logo">
        <a href="https://tassm.org.tw/"><img src="img/Logo.png" /></a>
      </div>
      <div class="burger_btn">
        <span></span>
      </div>
      <div class="main-menu">
        <ul>
          <template v-for="m in Menu">
            <li :class="{ hasChild: m.Children }" :key="m.MenuID" v-if="m.ShowInMenu && m.Active">
              <a href="javascript:void(0);" @click="go(m, 1)" :class="{ on: CurrentMenu == m }">{{ m.Title }}</a>
              <ul class="sub-menu" v-if="m.Children">
                <template v-for="m1 in m.Children">
                  <li :class="{ subHasChild: m1.Children }" :key="m1.MenuID" v-if="m1.ShowInMenu && m1.Active">
                    <a href="javascript:void(0);" @click="go(m1, 2)" :class="{ on: CurrentSubMenu == m1 }">{{
                      m1.Title
                    }}</a>
                    <ul class="sub-sub-menu" v-if="m1.Children">
                      <template v-for="m2 in m1.Children">
                        <li :key="m2.MenuID" v-if="m2.ShowInMenu && m2.Active">
                          <a href="javascript:void(0);" @click="go(m2)">{{
                            m2.Title
                          }}</a>
                        </li>
                      </template>
                    </ul>
                  </li>
                </template>
              </ul>
            </li>
          </template>
        </ul>
        <!-- end menu -->
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      Menu: [],
      CurrentMenu: null,
      CurrentSubMenu: null
    };
  },
  mounted() {
    this.load();   
  },
  methods: {
    async load() {
      var menu = await this.$api.getMenu();
      var root = { Children: [], MenuID: null };
      this.createMenu(menu, root);
      this.Menu = root.Children;
    },
    createMenu(menu, parent) {
      menu.forEach((m) => {
        if (m.ParentMenuID == parent.MenuID) {
          if (!parent.Children) {
            parent.Children = [];
          }
          parent.Children.push(m);
          this.createMenu(menu, m);
        }
      });
    },
    async go(menu, level) {
      if (menu.Children && menu.Children.length > 0) {
        if (level == 1) {
          if (this.CurrentMenu == menu) {
            this.CurrentMenu = null;
          } else {
            this.CurrentMenu = menu;
          }
        } else if (level == 2) {
          if (this.CurrentSubMenu == menu) {
            this.CurrentSubMenu = null;
          } else {
            this.CurrentSubMenu = menu;
          }
        }
        return;
      }
      this.closeMenu();
      if (menu.Type == 1) {
        if (menu.MenuID == 1) {
          this.$router.push("/");
        } else {
          this.$router.push("/newslist/" + menu.MenuID);
        }
      } else if (menu.Type == 2) {
        this.$router.push("/lessons");
      } else if (menu.Type == 3) {
        this.$router.push("/talents");
      } else if (menu.Type == 4) {
        this.$router.push("/journals");
      } else {
        var id = await this.$api.getDocumentID(menu.MenuID);
        if (id) {
          this.$router.push("/document/" + id);
        }
      }
    },
    closeMenu() {
      $(".main-menu").removeClass("on");
    },
  },
};
</script>