import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from './plugins/api'
import './plugins/validate'
import './plugins/datetimepicker'
import './plugins/filter'
import './plugins/utils'
import './plugins/directive'

Vue.config.productionTip = false

Vue.prototype.$api = api;
window.$api = api;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
