<template>
  <div id="app">
    <div id="base" class="all">
      <Header />
      <router-view />
      <Footer />
      <div class="loading">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="lightbox">
        <div class="title"></div>
        <div class="filter"></div>
        <div class="arrowr"></div>
        <div class="arrowl"></div>
        <div class="close"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import user from "./plugins/user";
import Vue from "vue";

Vue.prototype.setBase = function (cssClass) {
  var base = $("#base");
  var classList = base.attr("class").split(/\s+/);
  $.each(classList, function (index, item) {
    if (item !== "all") {
      base.removeClass(item);
    }
  });
  base.addClass(cssClass);
};

Vue.prototype.verifyLoing = function () {
  if (!user.user) {
    this.$router.replace("/login");
  }
};

Vue.prototype.loadCustomJs = function () {
  setTimeout(() => $.getScript("/js/custom.js"));
};

window.userTypes = [
  "管理者",
  "永久會員",
  "個人會員",
  "學生會員",
  "團體會員",
  "計劃委員",
  "委員會委員",
  "執行秘書",
  "委員會召集人",
  "副秘書長",
  "秘書長",
  "顧問",
  "理監事",
  "副理事長",
  "名譽理事長",
  "理事長",
  "研習上課",
  "特殊專案人員(設施組)",
  "特殊專案人員(跑道計畫)",
  "榮譽會員",
  "一般使用者"
];
var userTypesOrder = [
  0, 14, 15, 13, 12, 11, 10, 8, 7, 6, 5, 9, 17, 1, 2, 3, 4, 16, 17, 18, 19, 20
];
Vue.filter("userTypeFormat", function (value) {
  if (typeof value == "number") {
    return window.userTypes[value];
  }
  var map = {};
  value.forEach((x) => (map[x] = true));
  console.log(map);
  for (var i in userTypesOrder) {
    var key = userTypesOrder[i];
  console.log(key);
    if (map[key]) {
      return window.userTypes[key];
    }
  }
});

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  mounted() {
    $(".close").click(function () {
      $(".lightbox").fadeOut(300);
      //$("h1").remove();
      $(".lightbox img").remove();
      $("html").css("overflow", "auto");
    });

    $(document).keyup(function (e) {
      if (e.keyCode == 27) {
        $(".lightbox").fadeOut(300);
        $(".lightbox img").remove();
        $("html").css("overflow", "auto");
      }
    });

    $(".arrowr").click(function () {
      var imgSrc = $(".lightbox img").attr("src");
      var search = $("section").find("img[src$='" + imgSrc + "']");
      var newImage = search.next().attr("src");
      /*$(".lightbox img").attr("src", search.next());*/
      $(".lightbox img").attr("src", newImage);
      $(".filter").css("background-image", "url(" + newImage + ")");

      if (!search.next().is(":last-child")) {
        $(".arrowl").css("display", "block");
      } else {
        $(".arrowr").css("display", "none");
      }
    });

    $(".arrowl").click(function () {
      var imgSrc = $(".lightbox img").attr("src");
      var search = $("section").find("img[src$='" + imgSrc + "']");
      var newImage = search.prev().attr("src");
      /*$(".lightbox img").attr("src", search.next());*/
      $(".lightbox img").attr("src", newImage);
      $(".filter").css("background-image", "url(" + newImage + ")");

      if (!search.prev().is(":first-child")) {
        $(".arrowr").css("display", "block");
      } else {
        $(".arrowl").css("display", "none");
      }
    });
  },
};
</script>
