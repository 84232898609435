import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/Forget.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('../views/Forgot.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/registerm',
    name: 'RegisterMember',
    component: () => import('../views/RegisterMember.vue')
  },
  {
    path: '/registerp',
    name: 'RegisterPrivate',
    component: () => import('../views/RegisterPrivate.vue')
  },
  {
    path: '/registerl',
    name: 'RegisterLearner',
    component: () => import('../views/RegisterLearner.vue')
  },
  {
    path: '/my',
    name: 'MemberCenter',
    component: () => import('../views/MemberCenter.vue')
  },
  {
    path: '/news/:id',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/document/:id',
    name: 'Document',
    component: () => import('../views/Document.vue')
  },
  {
    path: '/preview',
    name: 'Preview',
    component: () => import('../views/Preview.vue')
  },
  {
    path: '/lessons',
    name: 'LessonList',
    component: () => import('../views/LessonList.vue')
  },
  {
    path: '/lesson/:id',
    name: 'Lesson',
    component: () => import('../views/Lesson.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/newslist/:id',
    name: 'NewsList',
    component: () => import('../views/NewsList.vue')
  }, 
  {
    path: '/talents',
    name: 'TalentList',
    component: () => import('../views/TalentList.vue')
  },
  {
    path: '/talent/:id',
    name: 'Talent',
    component: () => import('../views/Talent.vue')
  },
  {
    path: '/journals',
    name: 'JournalList',
    component: () => import('../views/JournalList.vue')
  },
  {
    path: '/journal/:id',
    name: 'Journal',
    component: () => import('../views/Journal.vue')
  },
  {
    path: '/exam/:id',
    name: 'Exam',
    component: () => import('../views/Exam.vue')
  },
]

const router = new VueRouter({
  routes
})

router.afterEach((to, from) => {
  window.$("html, body").animate({ scrollTop: 0 }, 500);
});

export default router
