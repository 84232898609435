import Vue from 'vue'

Vue.directive('masonry', {
    inserted: el => {
        var e = $(el);
        var after = e.attr('after');
        var setup = () => {
            this.$nextTick(() => {
                $(el).masonry({
                    itemSelector: ".content_box",
                    animate: true,
                    horizontalOrder: true,
                    originTop: true,
                });
            });
        };
        if (after) {
            e[after](setup);
        } else {
            setup();
        }
    }
});

Vue.directive('slick', {
    inserted: el => {
        setTimeout(() => {
            $(el).slick({
                arrows: true,
                autoplay: true,
                //- adaptiveHeight: true
            });
        }, 500);
    }
});

Vue.directive('logoslick', {
    inserted: el => {
        setTimeout(() => {
            $(el).slick({
                dots: true,
                infinite: true,
                speed: 300,
                slidesToShow: 5,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: true,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ],
            });
        }, 500);
    }
});


Vue.directive('waterfall', {
    inserted: el => {
        setTimeout(() => {
            if ($(window).width() > 900) {
                $(el).waterfall();
            }
        }, 500);
    }
});

Vue.directive('qrtrigger', {
    inserted: el => {
        this.$nextTick(() => {
            $(el).click(function () {
                if ($(this).hasClass("act")) {
                    $(this).removeClass("act");
                } else {
                    $(".qr-trigger").removeClass("act");
                    $(this).addClass("act");
                }
                return false;
            });
        });
    }
});

Vue.directive('qrcode', {
    inserted: el => {
        var e = $(el);
        var text = e.attr('text');
        e.kjua({ text: text, size: 100 });
    }
});

Vue.directive('password', {
    inserted: el => {
        var e = $(el);
        var input = $('input', e);
        var img = $(`<img src="/img/eye_hide.svg" alt="" class="eye">`);
        img.on('click', ()=>{
            if(input.attr('type') == 'password'){
                img.attr('src', '/img/eye_show.svg');
                input.attr('type', 'text');
            }else{
                img.attr('src', '/img/eye_hide.svg');
                input.attr('type', 'password');
            }
        });
        e.append(img);
    }
});